export default {
   /*
 
    //desarrollo
      api_imagenes: 'http://192.168.88.243/levelfood',
     api_master: 'http://192.168.88.243:5001/',
     api_impresora: 'http://localhost:5000/', 
  
   //produccion
        api_imagenes: 'http://192.168.0.30/level',
     api_master: 'http://192.168.0.30:5001/',
     api_impresora: 'http://localhost:5000/', 

     //vps
     api_imagenes: 'http://level.artysys.com/level',
     api_master: 'http://level.artysys.com:5001/',
     api_impresora: 'http://localhost:5000/', 
   
   
 */
   
     api_imagenes: process.env.VUE_APP_URL,
     api_master: `${process.env.VUE_APP_URL}/`,
     api_worker: `${process.env.VUE_APP_URL_WORKER}/`,
     api_impresora: 'http://localhost:5000/', 
}
