<template>
  <v-app>
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>
    <menu-vertical-config :is-drawer-open.sync="isDrawerOpen2"></menu-vertical-config>
    <Alert />
    <v-app-bar app flat fixed color="dark">
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-6">
          <!-- Left Content -->
          <v-app-bar-nav-icon class="d-block d-lg-none me-2" @click="isDrawerOpen = !isDrawerOpen"></v-app-bar-nav-icon>
          <select-img> </select-img>

          <v-spacer></v-spacer> 
          <!-- Right Content -->

          <v-tooltip bottom v-if="funciones.validarAcciones(acciones.COORDENADAS_GENERAR_COORDENADA, -1)">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="ms-2" icon small @click="abrirHashClave()">
                <v-icon>
                  {{ icons.mdiLockOpenAlertOutline  }}
                </v-icon>
              </v-btn>
            </template>
            <span>Coordenadas</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon small v-bind="attrs" v-on="on" class="mr-2">
                <v-icon v-if="workerEstatus" color="success">
                  {{ icons.mdiCogPlayOutline }}
                </v-icon>
                <v-icon v-else color="error">
                  {{ icons.mdiCogStop }}
                </v-icon>
              </v-btn>
            </template>
            <span v-if="workerEstatus">Worker Corriendo</span>
            <span v-else>Worker Detenido o no esta instalado</span>
          </v-tooltip>

          <theme-switcher v-bind="attrs" v-on="on" class="ms-2"></theme-switcher>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="ms-2" icon small @click="isDrawerOpen2 = !isDrawerOpen2">
                <v-icon>
                  {{ icons.mdiMenuOpen }}
                </v-icon>
              </v-btn>
            </template>
            <span>Abirir menu config</span>
          </v-tooltip>
          
        
          
          <app-bar-user-menu @AbrirAvatar="AbrirAvatar" @AbrirConfig="AbrirConfig"></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div class="app-content-container pa-6">
        <slot></slot>
      </div>
    </v-main>
    <v-footer dark padless v-if="this.$route.meta.IndFooter">
      <v-card class="flex" flat tile>
        <v-card-text class="white--text text-center">
          {{ new Date().getFullYear() }} — <strong>ArtySystem</strong>
        </v-card-text>
      </v-card>
    </v-footer>
    <avatar-select ref="AvatarSelectRef"></avatar-select>
    <ModalHashClave ref="ModalHashClaveRef"  />
    <ConsultarMonedas></ConsultarMonedas>
  </v-app>
</template>

<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiMagnify, mdiBellOutline, mdiGithub, mdiCogOutline, mdiMenuOpen ,mdiLockOpenAlertOutline } from '@mdi/js'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import SelectImg from './components/SelectImg.vue'
import store from '@/store'
import router from '@/router'
import axios from '@/api'
import Alert from '@/layouts/Alert.vue'
import MenuVerticalConfig from './components/MenuVerticalConfig.vue'
import AvatarSelect from '@/components/AvatarSelect.vue'
import SeguridadServices from '@/api/servicios/SeguridadServices'
import EmpresaServices from '@/api/servicios/EmpresaServices.js'
import LogServices from '@/api/worker/LogServices.js'
import ModalHashClave from '@/components/ModalHashClave.vue'
import funciones from '@/funciones/funciones'
import { acciones } from '@/modulos'
import ConsultarMonedas from '@/views/sistema/configuracion/moneda/componentes/ConsultarMonedas.vue'
export default {
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
    SelectImg,
    Alert,
    MenuVerticalConfig,
    AvatarSelect,
    ModalHashClave,
    ConsultarMonedas
  },
  setup() {
    const mdiCogPlayOutline =
      'M13.53 22H10C9.75 22 9.54 21.82 9.5 21.58L9.13 18.93C8.5 18.68 7.96 18.34 7.44 17.94L4.95 18.95C4.73 19.03 4.46 18.95 4.34 18.73L2.34 15.27C2.21 15.05 2.27 14.78 2.46 14.63L4.57 12.97C4.53 12.65 4.5 12.33 4.5 12S4.53 11.34 4.57 11L2.46 9.37C2.27 9.22 2.21 8.95 2.34 8.73L4.34 5.27C4.46 5.05 4.73 4.96 4.95 5.05L7.44 6.05C7.96 5.66 8.5 5.32 9.13 5.07L9.5 2.42C9.54 2.18 9.75 2 10 2H14C14.25 2 14.46 2.18 14.5 2.42L14.87 5.07C15.5 5.32 16.04 5.66 16.56 6.05L19.05 5.05C19.27 4.96 19.54 5.05 19.66 5.27L21.66 8.73C21.78 8.95 21.73 9.22 21.54 9.37L19.43 11C19.47 11.34 19.5 11.67 19.5 12V12.19C19 12.07 18.5 12 18 12C17.08 12 16.22 12.21 15.44 12.58C15.47 12.39 15.5 12.2 15.5 12C15.5 10.07 13.93 8.5 12 8.5S8.5 10.07 8.5 12 10.07 15.5 12 15.5C12.2 15.5 12.39 15.47 12.58 15.44C12.21 16.22 12 17.08 12 18C12 19.54 12.58 20.94 13.53 22M16 15V21L21 18L16 15Z'
    const mdiCogStop =
      'M13.53 22H10C9.75 22 9.54 21.82 9.5 21.58L9.13 18.93C8.5 18.68 7.96 18.34 7.44 17.94L4.95 18.95C4.73 19.03 4.46 18.95 4.34 18.73L2.34 15.27C2.21 15.05 2.27 14.78 2.46 14.63L4.57 12.97C4.53 12.65 4.5 12.33 4.5 12S4.53 11.34 4.57 11L2.46 9.37C2.27 9.22 2.21 8.95 2.34 8.73L4.34 5.27C4.46 5.05 4.73 4.96 4.95 5.05L7.44 6.05C7.96 5.66 8.5 5.32 9.13 5.07L9.5 2.42C9.54 2.18 9.75 2 10 2H14C14.25 2 14.46 2.18 14.5 2.42L14.87 5.07C15.5 5.32 16.04 5.66 16.56 6.05L19.05 5.05C19.27 4.96 19.54 5.05 19.66 5.27L21.66 8.73C21.78 8.95 21.73 9.22 21.54 9.37L19.43 11C19.47 11.34 19.5 11.67 19.5 12C19.5 12.06 19.5 12.12 19.5 12.19C19 12.07 18.5 12 18 12C17.08 12 16.22 12.21 15.44 12.58C15.47 12.39 15.5 12.2 15.5 12C15.5 10.07 13.93 8.5 12 8.5S8.5 10.07 8.5 12 10.07 15.5 12 15.5C12.2 15.5 12.39 15.47 12.58 15.44C12.21 16.22 12 17.08 12 18C12 19.54 12.58 20.94 13.53 22M21 15H15V21H21V15Z'
    const ModalHashClaveRef = ref(null)
    const AvatarSelectRef = ref(null)
    const isDrawerOpen = ref(null)
    const isDrawerOpen2 = ref(null)
    const workerEstatus = ref(false)
    let counterConsultas = ref(0)
    onBeforeMount(() => {
      setTimeout(() => {
        consultarWorker(true)
      }, 1000)
    })

    watch(counterConsultas, () => {
      setTimeout(() => {
        consultarWorker(true)
      }, 5000)
    })
    const abrirHashClave = ()=>{
      ModalHashClaveRef.value.abrirModal(true)
    }
    const AbrirAvatar = () => {
      AvatarSelectRef.value.abrir()
    }

    const AbrirConfig = () => {
      isDrawerOpen2.value = true
    }

    onBeforeMount(() => {
      cargarEmpresa()
      consultarWorker()
    })

    const consultarWorker = actualizar => {
      LogServices.Estatus()
        .then(response => {
          if (response.data.estatus) {
            workerEstatus.value = true
            store.commit('setWorkerStatus', true)
          } else {
            workerEstatus.value = false
            store.commit('setWorkerStatus', false)
          }
        })
        .catch(error => {
          workerEstatus.value = false
          store.commit('setWorkerStatus', false)
        })
        .finally(() => {
          if (actualizar == true) counterConsultas.value += 1
        })
    }

    const cargarEmpresa = () => {
      EmpresaServices.EmpresaConsultar({ id: store.state.user.idEmpresa })

        .then(response => {
          if ((response.data.estatus = true)) {
            console.log('EmpresaServices', response.data.datos)
            store.commit('setEmpresa', response.data.datos[0])
          }
        })
        .catch(error => {
          console.log('error Cargadno empresa', error)
        })
    }

    store.commit('setAlert', {}, { root: true })
    if (!store.state.user.isAutenticated) {
      router.push('/pages/login')
    } else {
      if (axios.defaults.headers.common['Authorization'] == undefined) {
        console.log(axios.defaults.headers.common['Authorization'])
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.user.token

        SeguridadServices.IniciarSesionToken(store.state.user.idUsuario, store.state.user.idEmpresa)
          .then(function (response) {
            if (response.data.estatus) {
              store.commit('setUserData', { ...response.data.datos, autenticate: true })
            } else {
              store.commit('setUserData', { autenticate: false })
              router.push('/pages/login')
            }
            console.log(response)
          })
          .catch(function (error) {
            if (error.response.status == 403) {
              store.commit('setUserData', { autenticate: false })
              router.push('/pages/login')
            }
            console.error(error)
          })

        /*
        axios
          .post('/login/iniciarSesionToken', {
            user: store.state.user.id,
            token: store.state.user.token,
          })*/
      }
    }
    const attrs = ref(null)
    const on = ref(null)
    return {
      consultarWorker,
      workerEstatus,
      isDrawerOpen,
      isDrawerOpen2,
      AbrirConfig,
      router,
      attrs,
      on,
      ModalHashClaveRef,
      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
        mdiCogOutline,
        mdiCogPlayOutline,
        mdiCogStop,
        mdiMenuOpen,
        mdiLockOpenAlertOutline 
      },
      store,
      AbrirAvatar,
      AvatarSelectRef,
      abrirHashClave,
      funciones,
      acciones
    }
  },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
</style>
