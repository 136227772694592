//import { axios } from '@/api';Categoria
import Axios from 'axios';
import store from '@/store'
import http from "..";
import router from '@/router'

class MonedaServices {
  Consultar(
    datos = {
      Id: -1,
      Descripcion: '',
      indActivo: null
    }
  ) {
    return http.get("moneda/consultar", { params: datos })
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }

  ConsultarSucursal(
    datos = {
      Id: -1,
      Descripcion: '',
      indActivo: null,
      idSucursal: -1
    }
  ) {
    return http.get("moneda/sucursal/consultar", { params: datos })
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }
  ConsultarEmpresa(
    datos  
  ) {
    return http.get("moneda/empresa/consultar", { params: datos })
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }

  



  ConsultarHistorial(
    datos
  ) {
    return http.get("moneda/historial/consultar/paginado", { params: datos })
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }
  ActualizarTasa(
    IdUsuario,
    Data
  ) {
    const datos = {
      IdUsuario,
      Data
    }
    return http.post("/moneda/tasa/actualizar", datos)
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }
}


export default new MonedaServices();