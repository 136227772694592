
export const sistema = {
    administracion: 1,
    comanda: 2,
    puesto: 3
}

export const modulo = {

    inventario_compra: 27,
    inventario_ajustes: 9,
    inventario_translado: 55,
    inventario_reportes: 52,
    inventario_archivo_tipoajuste: 50,
    inventario_archivo_area: 4,
    inventario_archivo_motivoajuste: 51,
    inventario_produccion_recetas: 6,
    inventario_produccion_ordernfabricacion: 7,
    inventario_produccion_fabricacion: 8,
    venta_pos: 11,
    producto_maestro_categoria: 15,
    producto_maestro_subcategoria: 22,
    producto_maestro_sublinea: 23,
    producto_maestro_linea: 16,
    producto_maestro_color: 17,
    producto_maestro_marca: 18,
    producto_maestro_modelo: 21,
    producto_maestro_unidadmedida: 19,
    producto_maestro_moneda: 20,
    producto_catalogo_producto: 25,
    producto_importar: 76,
    producto_catalogo_proveedor: 26,
    producto_catalogo_lote: 73,
    inventario_compra_factura: 28,
    inventario_compra_ordencompra: 29,
    inventario_compra_recepcion: 30,
    inventario_ajustes_cargadescarga: 32,
    inventario_ajustes_ajuste: 33,
    ventas_facturacion: 35,
    ventas_pedido: 36,
    ventas_reportes_aqueodia: 38,
    ventas_reportes_ventasporformadepago: 39,
    dashboard_inicio: 41,
    dashboard_venta: 42,
    configuracion_empleado: 44,
    configuracion_sucursal: 45,
    configuracion_empresa: 46,
    configuracion_imagen: 65,
    crm_cliente: 49,
    inventario_reportes_ajustedetalles: 53,
    inventario_reportes_compradetallado: 54,
    invnetario_reporte_existenciaprodcuto: 57,
    inventario_reportes_movimientoagrupado: 58,
    inventario_reportes_movimientoadetallado: 59,
    inventario_translado_interno: 47,
    inventario_translado_externo: 56,
    ecommerce_banner: 61,
    ecommerce_colecciones: 62,
    ecommerce_promocion: 63,
    seguridad_rol: 67,
    seguridad_permiso: 68,
    ventas_maestros_formaspago: 70,
    ventas_reporte_factura: 72,
    ventas_maestros_cajas: 71,
    venta_costo_precio: 74,
    ventas_cuentas_por_cobrar: 75,
    inventario_importar: 76,
    inventario_notaentrada: 78,
    inventario_notasalida: 79

}

export const acciones = {
    AREA_ALMACEN_VER: 2,
    PRODUCTO_VER: 3,
    RECETAS_VER: 4,
    ORDEN_DE_FABRICACION_VER: 5,
    FABRICACION_VER: 6,
    CATEGORIAS_VER: 7,
    LINEA_VER: 8,
    COLOR_VER: 9,
    MARCA_VER: 10,
    UNIDAD_MEDIDA_VER: 11,
    MONEDA_VER: 12,
    MODELO_VER: 13,
    SUBCATEGORIA_VER: 14,
    SUBLINEA_VER: 15,
    PROVEEDOR_VER: 16,
    FACTURA_VER: 17,
    ORDEN_COMPRA_VER: 18,
    RECEPCION_VER: 19,
    CARGA_DESCARGA_VER: 20,
    AJUSTE_INVENTARIO_VER: 21,
    FACTURACION_VER: 22,
    PEDIDO_VER: 23,
    ARQUEO_DIA_VER: 24,
    VENTAS_POR_FORMAS_DE_PAGO_VER: 25,
    INICIO_VER: 26,
    VENTAS_VER: 27,
    EMPLEADO_VER: 28,
    SUCURSAL_VER: 29,
    EMPRESA_VER: 30,
    TRANSLADO_VER: 31,
    CLIENTE_VER: 32,
    TIPO_AJUSTE_VER: 33,
    MOTIVO_AJUSTE_VER: 34,
    AJUSTES_DETALLADO_VER: 35,
    COMPRAS_DETALLADO_VER: 36,
    EXISTENCIA_PRODUCTO_VER: 38,
    MOVIMIENTO_AGRUPADO_VER: 39,
    MOVIMIENTO_DETALLADO_VER: 40,
    BANNER_VER: 41,
    COLECCION_VER: 42,
    PROMOCION_VER: 43,
    PRINCIPAL_VER: 44,
    MULTIMEDIA_VER: 45,
    ROLES_VER: 46,
    CREDENCIALES_VER: 47,
    FORMAPAGO_VER: 48,
    COSTO_PRECIOS_VER: 49,
    LOTES_VER: 50,
    CAJAS_VER: 51,
    FACTURAS_VER: 52,
    IMPORTAR_VER: 53,
    NOTA_DE_ENTRADA_VER: 54,
    NOTA_DE_SALIDA_VER: 55,
    CUENTAS_POR_COBRAR_VER: 56,
    VENTAS_POR_PRODUCTO_SUCURSAL_VER: 57,
    COORDENADAS_GENERAR_COORDENADA: 59,
    NOTA_DE_ENTRADA_ANULAR: 60,
    NOTA_DE_ENTRADA_CREAR: 61,
    NOTA_DE_ENTRADA_ACTUALIZAR: 62,
    FACTURA_ANULAR:63,
    COSTO_PRECIO_ACTUALIZAR:64,
    AJUSTE_ANULAR:65,
    NOTA_DE_SALIDA_ANULAR: 66,
    EXPORTAR_TODA_LA_DATA: 67
}

