


import store from '@/store';
import { read, utils, writeFile } from 'sheetjs-style'
import moment from 'moment'
import Vue from 'vue'
import SeguridadServices from '@/api/servicios/SeguridadServices';
class FuncionesGenerales {

    roundToTwo = (num, decimalPlaces = 4) => {
        const factorOfTen = Math.pow(10, decimalPlaces)
        return (Math.round((num) * factorOfTen)) / factorOfTen;
    }
    equalNumber = (x, y) => {
        return Math.abs(x - y) < Number.EPSILON;
    }

    formatoFecha = (fecha, tipo = 1) => {
        //tipo 1 solo fecha 10/10/2010
        //tipo 2 solo hora 02:02:00 pm
        //tipo 3 hora y fecha 10/10/2010 02:02:00 pm
        //tipo 4 hora y fecha 2000-10-01
        //tipo h fecha iso

        if (tipo == 1) {
            return moment(fecha).format("DD/MM/YYYY")   // new Date(fecha).toLocaleDateString('es-ve', { day: "2-digit", month: "2-digit", year: "numeric" })
        } else if (tipo == 2) {
            return moment(fecha).format("h:mm:ss a") //new Date(fecha).toLocaleTimeString('es-ve', { hour: "2-digit", minute: "2-digit", second: "2-digit" })
        } else if (tipo == 3) {
            return moment(fecha).format("DD/MM/YYYY, h:mm:ss a") //new Date(fecha).toLocaleDateString('es-ve', { day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit" })
        } else if (tipo == 4) {
            return moment(fecha).format("YYYY-MM-DD") //new Date(fecha).toLocaleDateString("fr-CA", { day: "2-digit", month: "2-digit", year: "numeric" })
        } else if (tipo == 5) {
            return moment(fecha).format("YYYYMMDD") //new Date(fecha).toLocaleDateString("fr-CA", { day: "2-digit", month: "2-digit", year: "numeric" })

        } else {
            return moment(fecha).format("DD/MM/YYYY, h:mm:ss a")  //new Date(fecha).toLocaleDateString('es-ve', { day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit" })
        }

    }

    camelCase = (textos, SoloPrimero = false) => {
        if (textos != undefined && textos != null && textos != '') {
            const arr = textos?.toLowerCase().split(" ")
            var text = ""
            console.log(text)
            console.log(arr)
            arr.forEach((element, i) => {
                if (i == 0) {
                    if (element.toString().length > 0) {
                        text = element[0].toUpperCase() + element.slice(1);
                    }
                } else {
                    if (!SoloPrimero) {
                        if (element.toString().length) {
                            text = text + " " + element[0].toUpperCase() + element.slice(1);
                        }

                    } else {
                        text = text + " " + element;
                    }
                }

            });
            return text
        } else {
            return ''
        }

    }
    uuidv4 = () => {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
        )
    }
    exportarDataExcelVariasHojas = (datos) => {

        const wb = utils.book_new();
        const format = {
            font: {
                bold: true,
            },
            alignment: {
                horizontal: "center",
                vertical: "center"
            },
        }


        datos.hojas.forEach(hoja => {
            //////CABECERA//////
            let ws_data = []
            let cabecera = []
            hoja.cabecera.forEach(item => {
                cabecera.push({ v: item.toUpperCase(), s: format })
            })

            ws_data.push(cabecera)
            ws_data = ws_data.concat(hoja.rows)

            // let ws = utils.aoa_to_sheet(ws_data);
            const ws = utils.aoa_to_sheet(ws_data);
            ws["!cols"] = hoja.anchoColumanas;
            utils.book_append_sheet(wb, ws, hoja.nombreHoja);
        })

        writeFile(wb, datos.nombreDocumento + '.xlsx');

    }


    exportarDataExcel = (datos) => {
        console.log(datos)
        const wb = utils.book_new();
        const format = {
            font: {
                bold: true,
            },
            alignment: {
                horizontal: "center",
                vertical: "center"
            },


        }

        //////CABECERA//////
        let ws_data = []
        let cabecera = []
        datos.cabecera.forEach(item => {
            cabecera.push({ v: item.toUpperCase(), s: format })
        })

        ws_data.push(cabecera)
        ws_data = ws_data.concat(datos.rows)

        // let ws = utils.aoa_to_sheet(ws_data);
        const ws = utils.aoa_to_sheet(ws_data);
        ws["!cols"] = datos.anchoColumanas;
        utils.book_append_sheet(wb, ws, datos.nombreHoja);

        writeFile(wb, datos.nombreDocumento + '.xlsx');

    }

    monedaPais = () => {
        const mo = store.state.user.monedas.find(i => i.indPais == true)
        return mo
    }
    monedaPrincipal = () => {
        const mo = store.state.user.monedas.find(i => i.indPrincipal == true)
        return mo
    }


    formatoNumeric = (number, precicion = 2) => {
        if (isNaN(number)) {
            return NaN;
        } else {
            return new Intl.NumberFormat('de-DE', {
                minimumFractionDigits: 2,
            }).format(Number(number.toFixed(precicion)))

        }
    }

    recorrerObjeto = (objeto) => {
        var stringFiltro = ""
        Object.keys(objeto).forEach(function (key) {
            if (objeto[key]) {
                console.log('Key : ' + key + ', Value : ' + objeto[key])
                if (stringFiltro === "") {
                    stringFiltro = key + '=' + objeto[key]
                } else {
                    stringFiltro += '&' + key + '=' + objeto[key]
                }
            }

        })
        return stringFiltro;
    }
    validarAcciones = (accion, sucursal) => {
   
        let valor = false
        if (sucursal == -1) {
            console.log(accion)
            console.log(store.state.user.accionModulo)
            console.log(store.state.user.accionModulo?.split(","))
            valor = store.state.user.accionModulo?.split(",").includes(accion.toString())
        } else {
           
            const accionM = store.state.user.sucursales.find(item => item.id == sucursal).accionModulo.split(",")
            if (accionM != null && accionM != undefined) {
                valor = accionM.includes(accion.toString())
            }
        }

        return valor
    }
    HashsConsultar = async () => {
        let Hashs = ""
        let returns = 0

        let timerInterval
        await Vue.swal({
            title: ' ',
            html: 'Consultando Coordenada',
            allowOutsideClick: false,
            didOpen: async () => {
                Vue.swal.showLoading()
                return await SeguridadServices.HashsConsultar()
                    .then(response => {
                        Hashs = response.data.datos.hashs
                        returns = 1
                        Vue.swal.close();
                        return true
                    })
                    .catch(error => {
                        returns = 0
                        Vue.swal.close();
                        return true
                    })
            }
        })


        if (returns == 0) {
            return returns
        }
        let intentos = 0
        await Vue.swal({
            icon: 'info',
            html:
                '<h3>Verifica Codigo</h3>' +
                `<h1 > <span style=" color: red;">${Hashs}</span> </h1> ` +
                ` Solicita la clave enviando el codigo en pantalla <br> ` +
                `<input style=" z-index: 50000;"  id="swal-motivo-anulacion"   class="swal2-input" placeholder="Inserta la Clave">  `,
            showCancelButton: true,
            confirmButtonText: 'Si, Seguro',
            showLoaderOnConfirm: true,
            cancelButtonText: 'No',
            preConfirm: async () => {
                const numero = document.getElementById('swal-motivo-anulacion').value
                if (numero != undefined && numero != '' && numero != null) {
                    returns = 2
                    return await SeguridadServices.HashsValidar(store.state.user.idUsuario, { Hashs, HashClave: numero })
                        .then(response => {
                            console.log(response.data)

                            if (response.data.estatus) {
                                return true
                            } else {
                                intentos += 1
                                if (intentos == 3) {
                                    Vue.swal(
                                        'Disculpe',
                                        response.data.datos.mensajeError,
                                        'error'
                                    )
                                } else {
                                    Vue.swal.showValidationMessage(response.data.datos.mensajeError + ` (intento n°${intentos})`)
                                }


                                return false
                            }

                        })
                        .catch(error => {
                            returns = 0
                            return false
                        })
                } else {
                    Vue.swal.showValidationMessage(`Debe proporcionar una clave valida`)
                }
            },
            allowOutsideClick: () => {
                !Vue.swal.isLoading()
                returns = 1
            },
        }).then(result => {
            console.log(result)
            if (result.isConfirmed) {
                returns = 3
            }
            //GetFiscalizando(false)
        })

        return returns
    }
}

export default new FuncionesGenerales();