//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "..";
import router from '@/router'

class SeguridadServices {
  IniciarSesion(datos) {
    return http.post("Seguridad/IniciarSesion", datos)
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }


  ArbolModuloGeneral(datos) {
    return http.get("Seguridad/ArbolModuloGeneral", { params: datos })
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }

  UsuarioConsultar(datos) {
    return http.get("Seguridad/usuario/consultar", { params: datos })
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }
  

  ArbolModulo(datos) {
    return http.get("Seguridad/ArbolModulo", { params: datos })
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }
  RolConsultar(datos) {
    return http.get("Seguridad/rol/consultar", { params: datos })
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }

  RolActualizar( idUsuario,data ) {   
    const datos = {
        IdUsuario: idUsuario,
        Data: data
      }
      console.log(datos) 
    return http.post("Seguridad/rol/actualizar",  datos )
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }

  UsuarioActualizar( idUsuario,data ) {   
    const datos = {
        IdUsuario: idUsuario,
        Data: data
      } 
    return http.post("Seguridad/usuario/actualizar",  datos )
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }

  
  IniciarSesionToken(IdUsuario, IdEmpresa) {
    return http.post("Seguridad/IniciarSesionToken", { IdUsuario,  IdEmpresa })
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }

  HashsConsultar() {
    return http.get("Seguridad/Hashs/Consultar" )
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }


  HashsValidar( idUsuario,data ) {   
    const datos = {
        IdUsuario: idUsuario,
        Data: data
      } 
    return http.post("Seguridad/Hashs/Validar",  datos )
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }

  
  HashClaveConsultar( idUsuario,data ) {   
    const datos = {
        IdUsuario: idUsuario,
        Data: data
      } 
    return http.post("Seguridad/HashClave/Consultar",  datos )
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }
  
}


export default new SeguridadServices();