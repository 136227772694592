<template>
  <div>
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-text class="text-center">
          <h1>Generar coordenada</h1>
          <p>Generador de claves de coordenadas inserte la coordenada</p>

          <v-otp-input :disabled="loading" @finish="consultar" v-model="otp" length="7"></v-otp-input>
   
          <h1>{{ hashsClave }}</h1>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn  small @click="limpiar()"> Limpiar </v-btn>
          <v-btn color="secondary" small @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  <script>
import { ref } from '@vue/composition-api'
import SeguridadServices from '@/api/servicios/SeguridadServices'
import store from '@/store'
export default {
  setup() {
    const dialog = ref(false)
    const otp = ref('')
    const hashsClave = ref('')
    const loading = ref(false)

    const limpiar = () => {
      hashsClave.value = ''
      otp.value = ''
    }
    const consultar = () => {
      hashsClave.value = ''
      loading.value = true
      SeguridadServices.HashClaveConsultar(store.state.user.idUsuario, { Hashs: otp.value })
        .then(response => {
          if (response.data.estatus) {
            hashsClave.value = response.data.datos.datos.hashClave
          } else {
            alert(response.data.datos.mensajeError)
            limpiar()
          }
        })
        .catch(error => {
          limpiar()
          alert('error')
        })
        .finally(() => {
          loading.value = false
        })
    }

    const abrirModal = valor => {
      limpiar()
      dialog.value = valor
    }
    return {
      dialog,
      abrirModal,
      otp,
      consultar,
      loading,
      hashsClave,
      limpiar,
    }
  },
}
</script>