<template>
  <small>  </small>
</template> 
<script>
import store from '@/store'
import { watch, ref, onBeforeMount } from '@vue/composition-api'
import MonedaServices from '@/api/servicios/MonedaServices'
import FuncionesGenerales from '@/funciones/funciones'
export default {
  setup() {
    let counterConsultas = ref(0)
    onBeforeMount(() => {
      cargar()
    })
    watch(counterConsultas, () => {
      setTimeout(() => {
        cargar()
      }, 5000)
    })

    const cargar = () => { 
      MonedaServices.ConsultarEmpresa({ idEmpresa: store.state.user.idEmpresa })
        .then(response => {
          console.log(response.data.datos)
          store.commit('setMonedaEmpresa', response.data.datos, { root: true })
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          counterConsultas.value += 1
        })
    }
    return {
      store,
      FuncionesGenerales,
    }
  },
}
</script>