import Vue from 'vue'
import VueRouter from 'vue-router'
import { sistema, modulo } from '@/modulos/index'
import store from '@/store'

Vue.use(VueRouter)

const ValidarPermiso = (to, from, next) => {
    next()

    if (to.meta.requiresAuth) {

        if (store.state.user.isAutenticated == true) {

            if (to.meta.modulo == undefined) {

                next()
            } else {

                const permisos = store.state.user.modulo?.split(",")
                const permisosSucursal = store.state.sucursalSelect.modulo.split(",")
        
                if (permisos?.includes(to.meta.modulo.toString()) || permisosSucursal?.includes(to.meta.modulo.toString())) {

                    next() // Take you to /something
                } else {
                    // If params.blah is blank or in your case, does not have permission, redirect back to the home page

                    next({ name: 'dashboard-inicio' })
                }
            }
        } else {
            // If params.blah is blank or in your case, does not have permission, redirect back to the home page
            next({ name: 'pages-login' })
        }
    } else {

        next() // Take you to /something
    }

}
const IrHome = (to, from, next) => {
    next({ name: 'dashboard-inicio' })
    /*const permisos = store.state.user.modulo
    if (permisos?.includes(modulo.dashboard_administracion)) {
        next({ name: 'dashboard-administracion' })
    } else {
        if (permisos?.includes(modulo.dashboard_comanda)) {
            next({ name: 'dashboard-comanda' })
        } else {
            if (permisos?.includes(modulo.dashboard_puesto)) {
                next({ name: 'dashboard-puesto' })
            } else {
                next({ name: 'inicio' })
            }

        }
    }*/
}

const general = [
    {
        path: '/',
        name: "index",
        //component: () => import('@/views/cards/Card.vue'),
        redirect: '/dashboard/inicio',
    },
    {
        path: '/inicio',
        name: 'inicio',
        component: () => import('@/views/cards/Card.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
        },
        beforeEnter: ValidarPermiso
    }
]

const productoArchivo = [
    {
        path: '/producto/importar',
        name: 'producto-importar',
        component: () => import('@/views/sistema/producto/importar/Importar.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.producto_importar
        },
        beforeEnter: ValidarPermiso
    },

    {
        path: '/producto/catalogo/producto',
        name: 'producto-catalogo-producto',
        component: () => import('@/views/sistema/producto/catalogo/producto/Producto.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.producto_catalogo_producto
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/producto/catalogo/proveedor',
        name: 'producto-catalogo-proveedor',
        component: () => import('@/views/sistema/producto/catalogo/proveedor/Proveedor.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.producto_catalogo_proveedor
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/producto/catalogo/lote',
        name: 'producto-catalogo-lote',
        component: () => import('@/views/sistema/producto/catalogo/lote/Lote.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.producto_catalogo_lote
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/producto/maestro/categoria',
        name: 'producto-maestro-categoria',
        component: () => import('@/views/sistema/producto/maestro/categoria/Categoria.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.producto_maestro_categoria
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/producto/maestro/linea',
        name: 'producto-maestro-linea',
        component: () => import('@/views/sistema/producto/maestro/linea/Linea.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.producto_maestro_linea
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/producto/maestro/sublinea',
        name: 'producto-maestro-sublinea',
        component: () => import('@/views/sistema/producto/maestro/sub-linea/SubLinea.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.producto_maestro_sublinea
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/producto/maestro/color',
        name: 'producto-maestro-color',
        component: () => import('@/views/sistema/producto/maestro/color/Color.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.producto_maestro_color
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/producto/maestro/marca',
        name: 'producto-maestro-marca',
        component: () => import('@/views/sistema/producto/maestro/marca/Marca.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.producto_maestro_marca
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/producto/maestro/modelo',
        name: 'producto-maestro-modelo',
        component: () => import('@/views/sistema/producto/maestro/modelo/Modelo.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
        },
        beforeEnter: ValidarPermiso,
        modulo: modulo.producto_maestro_modelo
    },
    {
        path: '/producto/maestro/unidadmedida',
        name: 'producto-maestro-unidadmedida',
        component: () => import('@/views/sistema/producto/maestro/unidad-medida/UnidadMedida.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
        },
        beforeEnter: ValidarPermiso,
        modulo: modulo.producto_maestro_unidadmedida
    },
    {
        path: '/producto/maestro/moneda',
        name: 'producto-maestro-moneda',
        component: () => import('@/views/sistema/producto/maestro/moneda/Moneda.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
        },
        beforeEnter: ValidarPermiso,

        modulo: modulo.producto_maestro_moneda
    },
    {
        path: '/producto/maestro/subcategoria',
        name: 'producto-maestro-subcategoria',
        component: () => import('@/views/sistema/producto/maestro/sub-categoria/SubCategoria.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
        },
        beforeEnter: ValidarPermiso,
        modulo: modulo.producto_maestro_subcategoria
    }

]

const inventarioArchivo = [
    {
        path: '/inventario/archivo/area',
        name: 'inventario-archivo-area',
        component: () => import('@/views/sistema/inventario/archivo/area/Area.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
        },
        beforeEnter: ValidarPermiso,

        modulo: modulo.inventario_archivo_area
    },
    {
        path: '/inventario/archivo/tipoajuste',
        name: 'inventario-archivo-tipoajuste',
        component: () => import('@/views/sistema/inventario/archivo/TipoAjuste/TipoAjuste.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
        },
        beforeEnter: ValidarPermiso,

        modulo: modulo.inventario_archivo_tipoajuste
    },
    {
        path: '/inventario/archivo/motivoajuste',
        name: 'inventario-archivo-motivoajuste',
        component: () => import('@/views/sistema/inventario/archivo/MotivoAjuste/MotivoAjuste.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
        },
        beforeEnter: ValidarPermiso,

        modulo: modulo.inventario_archivo_motivoajuste
    },

    {
        path: '/inventario/reportes/ajustedetalle',
        name: 'inventario-reportes-ajustedetalles',
        component: () => import('@/views/sistema/inventario/reportes/ajuste-detalles/AjustesDetalles.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
        },
        beforeEnter: ValidarPermiso,

        modulo: modulo.inventario_reportes_ajustedetalles
    },

    {
        path: '/inventario/reportes/compradetallado',
        name: 'inventario-reportes-compradetallado',
        component: () => import('@/views/sistema/inventario/reportes/compra-detallado/CompraDetallado.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
        },
        beforeEnter: ValidarPermiso,

        modulo: modulo.inventario_reportes_compradetallado
    }
    ,

    {
        path: '/inventario/reportes/existenciaprodcuto',
        name: 'invnetario-reporte-existenciaprodcuto',
        component: () => import('@/views/sistema/inventario/reportes/existencia-producto/ExistenciaProducto.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
        },
        beforeEnter: ValidarPermiso,

        modulo: modulo.invnetario_reporte_existenciaprodcuto
    },

    {
        path: '/inventario/reportes/movimientoagrupado',
        name: 'inventario-reportes-movimientoagrupado',
        component: () => import('@/views/sistema/inventario/reportes/movimiento-agrupado/MovimientoAgrupado.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
        },
        beforeEnter: ValidarPermiso,

        modulo: modulo.inventario_reportes_movimientoagrupado
    },
    {
        path: '/inventario/reportes/movimientoadetallado',
        name: 'inventario-reportes-movimientoadetallado',
        component: () => import('@/views/sistema/inventario/reportes/movimiento-detallado/MovimientoDetallado.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,

            modulo: modulo.inventario_reportes_movimientoadetallado
        },
        beforeEnter: ValidarPermiso
    }
]



const inventarioAjustes = [
    {
        path: '/inventario/ajustes/ajuste',
        name: 'inventario-ajustes-ajuste',
        component: () => import('@/views/sistema/inventario/ajuste/ajuste/Ajuste.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.inventario_ajustes_ajuste
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/inventario/ajustes/cargadescarga',
        name: 'inventario-ajustes-cargadescarga',
        component: () => import('@/views/sistema/inventario/ajuste/carga-descarga/CargaDescarga.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.inventario_ajustes_cargadescarga
        },
        beforeEnter: ValidarPermiso
    },


]


const inventarioTranslado = [
    {
        path: '/inventario/translado',
        name: 'inventario-translado',
        component: () => import('@/views/sistema/inventario/translado/translado/Translado.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.inventario_translado_interno
        },
        beforeEnter: ValidarPermiso
    }


]
const inventarioCompra = [
    {
        path: '/inventario/compra/factura',
        name: 'inventario-compra-factura',
        component: () => import('@/views/sistema/inventario/compra/factura/Factura.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.inventario_compra_factura
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/inventario/compra/ordencompra',
        name: 'inventario-compra-ordencompra',
        component: () => import('@/views/sistema/inventario/compra/orden-compra/OrdenCompra.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.inventario_compra_ordencompra
        },
        beforeEnter: ValidarPermiso
    },

    {
        path: '/inventario/compra/recepcion',
        name: 'inventario-compra-recepcion',
        component: () => import('@/views/sistema/inventario/compra/recepcion/Recepcion.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.inventario_compra_recepcion
        },
        beforeEnter: ValidarPermiso
    },
]



const inventarioExportImport = [
    {
        path: '/inventario/importar',
        name: 'inventario-importar',
        component: () => import('@/views/sistema/inventario/export-import/importar/Importar.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.inventario_importar
        },
        beforeEnter: ValidarPermiso
    },

]

const inventarioProduccion = [
    {
        path: '/inventario/produccion/fabricacion',
        name: 'inventario-produccion-fabricacion',
        component: () => import('@/views/sistema/inventario/produccion/fabricacion/Fabricacion.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.inventario_produccion_fabricacion
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/inventario/produccion/ordernfabricacion',
        name: 'inventario-produccion-ordernfabricacion',
        component: () => import('@/views/sistema/inventario/produccion/orden-fabricacion/OrdenFabricacion.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.inventario_produccion_ordernfabricacion
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/inventario/produccion/recetas',
        name: 'inventario-produccion-recetas',
        component: () => import('@/views/sistema/inventario/produccion/recetas/Recetas.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.inventario_produccion_recetas
        },
        beforeEnter: ValidarPermiso
    }

]

const inventarioNotaEntrada = [
    {
        path: '/inventario/notaentrada',
        name: 'inventario-notaentrada',
        component: () => import('@/views/sistema/inventario/nota-entrada/NotaEntrada.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.inventario_notaentrada
        },
        beforeEnter: ValidarPermiso
    }
]

const inventarioNotaSalida = [
    {
        path: '/inventario/notasalida',
        name: 'inventario-notasalida',
        component: () => import('@/views/sistema/inventario/nota-salida/NotaSalida.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.inventario_notasalida
        },
        beforeEnter: ValidarPermiso
    }
]


const dashboard = [
    {
        path: '/dashboard/inicio',
        name: 'dashboard-inicio',
        component: () => import('@/views/sistema/dashboard/inicio/Inicio.vue'),
        meta: {
            IndFooter: true,
            requiresAuth: true,
        },
    },
    {
        path: '/dashboard/gerencia',
        name: 'dashboard-gerencia',
        component: () => import('@/views/sistema/dashboard/gerencia/Gerencia.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.dashboard_venta
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/dashboard/ventas',
        name: 'dashboard-ventas',
        component: () => import('@/views/sistema/dashboard/venta/Venta.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.dashboard_venta
        },
        beforeEnter: ValidarPermiso
    }


]

const ventas = [
    {
        path: '/ventas/facturacion',
        name: 'ventas-facturacion',
        component: () => import('@/views/sistema/venta/facturacion/Facturacion.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.ventas_facturacion
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/ventas/pedido',
        name: 'ventas-pedido',
        component: () => import('@/views/sistema/venta/pedido/Pedido.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.ventas_pedido
        },
        beforeEnter: ValidarPermiso
    },

    {
        path: '/ventas/maestros/cajas',
        name: 'ventas-maestros-cajas',
        component: () => import('@/views/sistema/venta/maestro/caja/Caja.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.ventas_maestros_cajas
        },
        beforeEnter: ValidarPermiso
    },

    {
        path: '/ventas/maestros-formaspago',
        name: 'ventas-maestros-formaspago',
        component: () => import('@/views/sistema/venta/formaPago/FormaPago.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.ventas_maestros_formaspago
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/ventas/costo-precio',
        name: 'venta-costo-precio',
        component: () => import('@/views/sistema/venta/costo-precio/CostoPrecio.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.venta_costo_precio
        },
        beforeEnter: ValidarPermiso
    },
    // {
    //     path: '/ventas/costo-precio',
    //     name: 'venta-costo-precio',
    //     component: () => import('@/views/sistema/venta/costo-precio/CostoPrecio.vue'),
    //     meta: {
    //         IndFooter: false,
    //         requiresAuth: true,
    //         modulo: modulo.venta_costo_precio
    //     },
    //     beforeEnter: ValidarPermiso
    // },
    {
        path: '/ventas/cuentasporcobrar',
        name: 'ventas-cuentasporcobrar',
        component: () => import('@/views/sistema/venta/cuentasPorCobrar/CuentaPorCobrar.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.ventas_cuentasporcobrar
        },
        beforeEnter: ValidarPermiso
    },
]

const crm = [
    {
        path: '/crm/cliente',
        name: 'crm-cliente',
        component: () => import('@/views/sistema/crm/cliente/Cliente.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.crm_cliente
        },
        beforeEnter: ValidarPermiso
    }

]

const ventasReporte = [
    {
        path: '/ventas/reportes/ventasporproductosucursal',
        name: 'ventas-reportes-ventasporproductosucursal',
        component: () => import('@/views/sistema/venta/reporte/ventas-producto/VentasPorProducto.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.ventas_reportes_aqueodia
        },
        beforeEnter: ValidarPermiso
    }, {
        path: '/ventas/reportes/ventas/aqueodia',
        name: 'ventas-reportes-aqueodia',
        component: () => import('@/views/sistema/venta/reporte/arqueo-dia/ArqueoDia.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.ventas_reportes_aqueodia
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/ventas/reportes/ventasporformadepago',
        name: 'ventas-reportes-ventasporformadepago',
        component: () => import('@/views/sistema/venta/reporte/ventas-formas-pago/VentasFormasPago.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.ventas_reportes_ventasporformadepago
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/venta/reporte/factura',
        name: 'ventas-reporte-factura',
        component: () => import('@/views/sistema/venta/reporte/venta-reporte-factura/VentaReporteFactura.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.ventas_reporte_factura
        },
        beforeEnter: ValidarPermiso
    },

]

const ecommerce = [
    {
        path: '/ecommerce/banner',
        name: 'ecommerce-banner',
        component: () => import('@/views/sistema/ecommerce/banner/Banner.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.ecommerce_banner
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/ecommerce/promocion',
        name: 'ecommerce-promocion',
        component: () => import('@/views/sistema/ecommerce/promo/Promo.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.ecommerce_promocion
        },
        beforeEnter: ValidarPermiso
    }
]



const configuracion = [
    {
        path: '/configuracion/empleado',
        name: 'configuracion-empleado',
        component: () => import('@/views/sistema/configuracion/empleado/Empleado.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.configuracion_empleado
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/configuracion/sucursal',
        name: 'configuracion-sucursal',
        component: () => import('@/views/sistema/configuracion/sucursal/Sucursal.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.configuracion_sucursal
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/configuracion/imagen',
        name: 'configuracion-imagen',
        component: () => import('@/views/sistema/configuracion/imagen/Imagen.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.configuracion_imagen
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/configuracion/empresa',
        name: 'configuracion-empresa',
        component: () => import('@/views/sistema/configuracion/empresa/Empresa.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.configuracion_empresa
        },
        beforeEnter: ValidarPermiso
    }

]
const login = [

    {
        path: '/pages/login',
        name: 'pages-login',
        component: () => import('@/views/pages/Login.vue'),
        meta: {
            IndFooter: false,
            layout: 'blank',
        },
    },
    {
        path: '/pages/inicializar',
        name: 'pages-inicializar',
        component: () => import('@/views/pages/Inicializar.vue'),
        meta: {
            IndFooter: false,
            layout: 'blank',
        },
    },
    {
        path: '/pages/inicializardb',
        name: 'pages-inicializar-db',
        component: () => import('@/views/pages/InicializarDB.vue'),
        meta: {
            IndFooter: false,
            layout: 'blank',
        },
    }, {
        path: '/pages/actualizar/:version?',
        name: 'pages-actualizar',
        component: () => import('@/views/pages/Actualizar.vue'),
        meta: {
            IndFooter: false,
            layout: 'blank',
        },
    },




];
const erores = [
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/Error.vue'),
        meta: {
            IndFooter: false,
            layout: 'blank',
        },
    },
    {
        path: '*',
        redirect: 'error-404',
    },
]


const seguridad = [
    {
        path: '/seguridad/rol',
        name: 'seguridad-rol',
        component: () => import('@/views/sistema/seguridad/rol/Rol.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.seguridad_rol
        },
        beforeEnter: ValidarPermiso
    },
    {
        path: '/seguridad/permiso',
        name: 'seguridad-permiso',
        component: () => import('@/views/sistema/seguridad/permiso/Permiso.vue'),
        meta: {
            IndFooter: false,
            requiresAuth: true,
            modulo: modulo.seguridad_permiso
        },
        beforeEnter: ValidarPermiso
    }
]
const routes = [].concat(
    erores,
    login,
    general,
    inventarioArchivo,
    inventarioProduccion,
    inventarioCompra,
    inventarioAjustes,
    inventarioTranslado,
    inventarioNotaEntrada,
    inventarioNotaSalida,
    productoArchivo,
    dashboard,
    ventas,
    ventasReporte,
    configuracion,
    crm,
    ecommerce,
    seguridad,
    inventarioExportImport
)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes

})

export default router
