//import { axios } from '@/api'; 
import http from "."; 

class LogServices { 
    Estatus() {
        return http.get("LOG/estatus")
          .catch((error) => { 
          });
      }
}


export default new LogServices();