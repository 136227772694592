import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import axios from '@/api'
Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default new Vuex.Store({
  state: {
    version: '1.2.2',
    workerStatus: false, 
    user: {
      isAutenticated: false,
      userName: "",
      id: 0 ,
      IdEmpresa: 0,
      empresaActual:"",
      monedas:[]
    },
    sucursalSelect: {
      arbol:[]
    },
    alert: {},
    dark: false,
    tikera: {
      id: 1,
      nombre: "comanda 1"
    },
    impresoraFiscal:  {
      modeloImpresora: 1,
      puerto: 5,
      bAudios: 9600,
      paridad: 2,
      datos: 8,
      stopBits: 1,
      handshake: 0,
      maxTiempoEspera: 5000,
      tiempoAntesLectura: 100,
    } ,
    configuracionGeneral:{ }
  },
  mutations: {
    setAlert(state, datos) {
      state.alert = datos
    }, 
    setWorkerStatus(state, datos) {
      state.workerStatus = datos
    },
    setMonedaEmpresa (state, datos) {
      state.user.monedas = datos
    },
    setUserData(state, datos) {

      state.user = datos;
      if (datos.sucursales != undefined){
        if (state.sucursalSelect.id == undefined) { 
          state.sucursalSelect = datos.sucursales[0]
        }else{
          if (datos.sucursales.find(item=>item.id == state.sucursalSelect.id) == undefined ){
            state.sucursalSelect = datos.sucursales[0]
          }else{
            state.sucursalSelect  = datos.sucursales.find(item=>item.id == state.sucursalSelect.id)
          }
        }
        
      } 
      const token = datos.token
      if (token) {
        console.log("creo Authorization")
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      } else {
        delete axios.defaults.headers.common["Authorization"];
        console.log("borro Authorization")
      }
    },
    setEmpresa(state, objeto) {
      state.user.empresaActual = objeto;
    },
    setSucursalSelect(state, objeto) {
      state.sucursalSelect = objeto;
    },
    seTikera(state, datos) {
      state.tikera = datos
    },
    setImpresoraFiscal(state, datos) {
      ////alert(datos)
      state.impresoraFiscal = datos
    },
    seDark(state, datos) {
      state.tikera = datos
    },
    setconfiguracionGeneral(state, datos) {
      state.configuracionGeneral = datos
    },
     
  },
  actions: {},
  modules: {},
  plugins: [vuexLocal.plugin]
})
