<template>
   <v-fade-transition mode="out-in" > 
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
     <span v-bind="attrs" v-on="on" >
        <v-icon :key="$vuetify.theme.dark" @click="$vuetify.theme.dark = !$vuetify.theme.dark">
          {{ $vuetify.theme.dark ? icons.mdiWeatherSunny : icons.mdiWeatherNight }}
        </v-icon>
      </span>
    </template>
    <span>Cambiar Tema</span>
  </v-tooltip>
</v-fade-transition>
</template>

<script>
import { mdiWeatherNight, mdiWeatherSunny } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiWeatherNight,
        mdiWeatherSunny,
      },
    }
  },
}
</script>

<style>
</style>
