//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from ".."; 
import router from '@/router'

class EmpresaServices {
  EmpresaConsultarLogin() {
    return http.get("Empresa/consultarLogin")
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  } 


  EmpresaConsultar(datos) {
    return http.get("Empresa/consultar", { params: datos })
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }

  Actualizar(
    idUsuario,
    id,
    idTipoEmpresa,
    idMoneda,
    documento,
    razonSocial,
    razonComercial,
    registro,
    documentoResponsable,
    nombreResponsable,
    apellidoResponsable,
    telefonoResponsable,
    correoResponsable,
    imagen1,
    imagen2,
    direccion1,
    direccion2,
    direccion3,
    indActivo,
) {
    const datos = {
        IdUsuario: idUsuario,
        Data:{
          id: id,
          idTipoEmpresa: idTipoEmpresa,
          idMoneda: idMoneda, 
          documento: documento, 
          razonSocial: razonSocial,
          razonComercial: razonComercial, 
          registro: registro, 
          documentoResponsable: documentoResponsable, 
          nombreResponsable: nombreResponsable, 
          apellidoResponsable: apellidoResponsable, 
          telefonoResponsable: telefonoResponsable, 
          correoResponsable: correoResponsable, 
          imagen1: imagen1, 
          imagen2: imagen2, 
          direccion1: direccion1, 
          direccion2: direccion2, 
          direccion3: direccion3,
          indActivo: indActivo,
        }
    }
    console.log(datos)
    return http.post("/Empresa/actualizar", datos)
        .catch((error) => {
            if (error.response.status == 401) {
                store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                router.push("/pages/login");
            }
        });
}
}


export default new EmpresaServices();