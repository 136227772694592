<template>
  <v-snackbars style="z-index: 3000" :objects.sync="messages" :timeout="5000" top center>
    <template v-slot:default="{ message }">
      <h4 v-if="message.titulo" class="mb-2 white--text">
        <v-icon class="mr-2"> {{ message.icon }} </v-icon>{{ message.titulo }}
      </h4>
      {{ message.message }}
    </template>
    <template v-slot:action="{ close }">
      <v-btn icon @click="close()"
        ><v-icon> {{ icons.mdiClose }} </v-icon>
      </v-btn>
    </template>
  </v-snackbars>
</template>

<script>
import { watch, ref } from '@vue/composition-api'
import vSnackbars from 'v-snackbars'
import store from '@/store'
import { mdiClose, mdiAlertOutline, mdiAlertCircleOutline, mdiCheckCircleOutline,mdiInformationOutline } from '@mdi/js'
export default {
  watch: {
    '$store.state.alert': function (newVal, oldVal) {
      console.log('newVal', newVal)
      this.agregarMensaje(newVal)
    },
  },
  components: {
    'v-snackbars': vSnackbars,
  },
  setup() {
    const messages = ref([])
    const valor = ref(false)
    /* watch(this.$store.state.app, () => {
      if (valor.value == false) {
        valor.value = !!this.$store.state.app.alert.message
      }
    })*/
    watch(valor, () => {
      if (valor.value == false) {
        setTimeout(() => {
          store.commit('setAlert', {}, { root: true })
        }, 100)
      }
    })
    const closeAlert = () => {
      valor.value = false
    }

    const agregarMensaje = newVal => {
      var icon = null
      var titulo = ''
      console.log(newVal)
      if (newVal.icon) {
        icon = newVal.icon
      } else {
        switch (newVal.type) {
          case 'success':
            icon = mdiCheckCircleOutline
            titulo = 'Exito'
            break
          case 'error':
            icon = mdiAlertCircleOutline
            titulo = 'Error'
            break
          case 'warning':
            icon = mdiAlertOutline
            titulo = 'Advertencia'
            break
          case 'info':
            icon = mdiInformationOutline
            titulo = 'Informacion'
            break
          default:
            break
        }
      }

      if (newVal.titulo) {
        titulo = newVal.titulo
      }
      messages.value.push({
        ...newVal,
        color: newVal.type,
        message: {
          titulo: titulo,
          message: newVal.message,
          icon: icon,
        },
      })

      if (valor == false) {
        valor = !!newVal.message
      }
    }

    return {
      closeAlert,
      valor,
      messages,
      agregarMensaje,
      icons: {
        mdiClose,
        mdiAlertOutline,
        mdiAlertCircleOutline,
        mdiCheckCircleOutline,
        mdiInformationOutline
      },
    }
  },
}
</script>