require('./overrides.scss')

export default {
  theme: {
    themes: {
      light: {
        primary: '#9155FD',
        accent: '#0d6efd',
        secondary: '#8A8D93',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51', 
        successLight: '#DBFFDC', 
        purple_4: '#E1BEE7',
        primary2: '#BA9BF2',
        primary3: '#8951F0',
        primary4: '#6C40BD',
        primary5: '#564870',
        grey1: '#FAFAFA',
        grey2: '#F5F5F5',
        grey3: '#EEEEEE',
        grey4: '#E0E0E0',
        grey5: '#BDBDBD',
        error1: '#FFEBEE',
      },
      dark: {
        primary: '#9155FD',
        accent: '#0d6efd',
        secondary: '#8A8D93',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
        successLight: '#0A3D10', 
        purple_4: '#E1BEE7',
        primary2: '#BA9BF2',
        primary3: '#8951F0',
        primary4: '#6C40BD',
        primary5: '#564870',
        grey1: '#28243d',
        grey2: '#F5F5F5',
        grey3: '#5A5480',
        grey4: '#8A8D93',
        grey5: '#BDBDBD',
        error1: '#FFEBEE',
      },
    },
    themes2: {
      light: {
        primary: '#FF9A76',
        accent: '#0d6efd',
        secondary: '#637373',
        success: '#25CED1',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51', 
        purple_4: '#E1BEE7',
        primary2: '#BA9BF2',
        primary3: '#8951F0',
        primary4: '#6C40BD',
        primary5: '#564870',
        grey1: '#FAFAFA',
        grey2: '#F5F5F5',
        grey3: '#EEEEEE',
        grey4: '#E0E0E0',
        grey5: '#BDBDBD',
        error1: '#FFEBEE',
      },
      dark: {
        primary: '#FF9A76',
        accent: '#0d6efd',
        secondary: '#637373',
        success: '#637373',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
        purple_4: '#E1BEE7',
        primary2: '#BA9BF2',
        primary3: '#8951F0',
        primary4: '#6C40BD',
        primary5: '#564870',
        grey1: '#FAFAFA',
        grey2: '#F5F5F5',
        grey3: '#EEEEEE',
        grey4: '#E0E0E0',
        grey5: '#BDBDBD',
        error1: '#FFEBEE',
      },
    },
  },
}
