<template>
  <div class="conten">
    <v-navigation-drawer
      :mini-variant.sync="miniVari"
      mini-variant-width="70"
      :expand-on-hover="mini"
      dark
      :value="isDrawerOpen"
      app
      floating
      width="260"
      class="app-navigation-menu"
      :right="$vuetify.rtl"
      @input="val => $emit('update:is-drawer-open', val)"
    >
      <!-- Navigation Header -->
      <div class="vertical-nav-header d-flex items-center ps-5 pe-5 pt-5 pb-2">
        <router-link to="/" class="d-flex align-center text-decoration-none">
          <v-img
           v-if="miniVari"
            :src="require('@/assets/images/logos/logo.png')"
            max-height="30px"
            max-width="30px"
            alt="logo"
            contain
            eager
            class="app-logo me-3"
          ></v-img>
          <v-slide-x-transition>
            <v-img
              v-if="!miniVari"
              :src="require(`@/assets/images/logos/logoArty.png`)"
              max-height="30"
              alt="logo"
              width="80"
            ></v-img>
          </v-slide-x-transition>
        </router-link>
        <v-slide-x-transition>
          <v-btn
            v-if="!miniVari"
            icon
            @click="
              mini = !mini
              miniVari = false
            "
          >
            <v-icon>{{ mini ? icons.mdiRadioboxBlank : icons.mdiRadioboxMarked }}</v-icon>
          </v-btn>
        </v-slide-x-transition>
      </div>

      <!-- Navigation Items -->

      <v-list expand shaped class="vertical-nav-menu-items">
        <div v-for="(item, index) in store.state.sucursalSelect.arbol" :key="index">
          <nav-menu-section-title v-if="(mini == true && miniVari == false) || mini == false" :title="item.nombre">
          </nav-menu-section-title>
          <nav-menu-link v-else :icon="icons.mdiMinus"></nav-menu-link>
          <div v-for="(item2, index2) in item.children" :key="index2">
            <nav-menu-link
              :icon="item2.icono"
              :title="item2.nombre"
              v-if="item2.children.length == 0"
              :to="{ name: item2.ruta }"
            ></nav-menu-link>
            <nav-menu-group v-else :title="item2.nombre" :icon="item2.icono">
              <div v-for="(item3, index3) in item2.children" :key="index3">
                <nav-menu-link
                  sub-group
                  :icon="item3.icono"
                  :title="item3.nombre"
                  v-if="item3.children.length == 0"
                  :to="{ name: item3.ruta }"
                ></nav-menu-link>
                <nav-menu-group v-else :title="item3.nombre" :icon="item3.icono">
                  <div v-for="(item4, index4) in item3.children" :key="index4">
                    <nav-menu-link :title="item4.nombre" :to="{ name: item4.ruta }" :icon="item4.icono"></nav-menu-link>
                  </div>
                </nav-menu-group>
              </div>
            </nav-menu-group>
          </div>
        </div>
      </v-list>

      <v-list expand shaped class="vertical-nav-menu-items">
        <div v-for="(item, index) in store.state.user.arbol" :key="index">
          <nav-menu-section-title v-if="(mini == true && miniVari == false) || mini == false" :title="item.nombre">
          </nav-menu-section-title>
          <nav-menu-link v-else :icon="icons.mdiMinus"></nav-menu-link>
          <div v-for="(item2, index2) in item.children" :key="index2">
            <nav-menu-link
              :icon="item2.icono"
              :title="item2.nombre"
              v-if="item2.children.length == 0"
              :to="{ name: item2.ruta }"
            ></nav-menu-link>
            <nav-menu-group v-else :title="item2.nombre" :icon="item2.icono">
              <div v-for="(item3, index3) in item2.children" :key="index3">
                <nav-menu-link
                  sub-group
                  :icon="item3.icono"
                  :title="item3.nombre"
                  v-if="item3.children.length == 0"
                  :to="{ name: item3.ruta }"
                ></nav-menu-link>
                <nav-menu-group v-else :title="item3.nombre" :icon="item3.icono">
                  <div v-for="(item4, index4) in item3.children" :key="index4">
                    <nav-menu-link :title="item4.nombre" :to="{ name: item4.ruta }" :icon="item4.icono"></nav-menu-link>
                  </div>
                </nav-menu-group>
              </div>
            </nav-menu-group>
          </div>
        </div>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiAlphaTBoxOutline,
  mdiEyeOutline,
  mdiCreditCardOutline,
  mdiTable,
  mdiFileOutline,
  mdiFormSelect,
  mdiAccountCogOutline,
  mdiFileDocumentMultiple,
  mdiCashRegister,
  mdiAccountCash,
  mdiReceipt,
  mdiCartOutline,
  mdiArchive,
  mdiRadioboxMarked,
  mdiRadioboxBlank,
  mdiMinus,
} from '@mdi/js'
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'
import store from '@/store'
import { watch, ref } from '@vue/composition-api'

console.log(store.state.user.arbolPermisos)

const Iconos = () => {
  return mdiHomeOutline
}

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  setup() {
    const drawer = ref(true)
    const mini = ref(false)
    const miniVari = ref(false)

    return {
      icons: {
        mdiHomeOutline,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiCreditCardOutline,
        mdiTable,
        mdiFileOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
        mdiFileDocumentMultiple,
        mdiCashRegister,
        mdiAccountCash,
        mdiReceipt,
        mdiCartOutline,
        mdiArchive,
        mdiRadioboxMarked,
        mdiRadioboxBlank,
        mdiMinus,
      },
      Iconos,
      store,
      drawer,
      mini,
      miniVari,
    }
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;

  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
