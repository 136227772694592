<template>
    <v-sheet class="overflow-hidden">
        <v-navigation-drawer width="300" :value="isDrawerOpen" absolute temporary  dark floating hide-overlay touchless fixed
            @input="val => $emit('update:is-drawer-open', val)">
            <v-list-item>
                <v-list-item-avatar>
                    <v-img :src="`${config.api_imagenes}/assets/images/empleado/${store.state.user.foto}`"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title> {{ store.state.user.nombre }} {{ store.state.user.apellido }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>


            <v-divider></v-divider>
            <v-container>
                <v-row>

                    <v-subheader>
                        Tickera
                    </v-subheader>
                    <v-col class="d-flex" cols="12">
                        <v-select :items="impresoras" :value="store.state.tikera" @change="selectTikera" item-value="id"
                            item-text="nombre" label="Tickera Default" dense outlined></v-select>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row v-if="validarAcciones(acciones.DASHBOARD_COMANDA_FISCALIZAR_COMANDA)">
                    <v-col class="d-flex" cols="12">
                        <v-subheader>
                            Impresora fiscal
                        </v-subheader>
                    </v-col>

                    <v-col class="d-flex" cols="6">
                        <v-text-field label="modeloImpresora" outlined dense hide-details v-model="modeloImpresora">
                        </v-text-field>
                    </v-col>
                    <v-col class="d-flex" cols="6">
                        <v-text-field label="bAudios" outlined dense hide-details v-model="bAudios">
                        </v-text-field>
                    </v-col>
                    <v-col class="d-flex" cols="6">
                        <v-text-field label="paridad" outlined dense hide-details v-model="paridad">
                        </v-text-field>
                    </v-col>
                    <v-col class="d-flex" cols="6">
                        <v-text-field label="puerto" outlined dense hide-details v-model="puerto">
                        </v-text-field>
                    </v-col>

                    <v-col class="d-flex" cols="6">
                        <v-text-field label="stopBits" outlined dense hide-details v-model="stopBits">
                        </v-text-field>
                    </v-col>
                    <v-col class="d-flex" cols="6">
                        <v-text-field label="handshake" outlined dense hide-details v-model="handshake">
                        </v-text-field>
                    </v-col>
                    <v-col class="d-flex" cols="6">
                        <v-text-field label="maxTiempoEspera" outlined dense hide-details v-model="maxTiempoEspera">
                        </v-text-field>
                    </v-col>
                    <v-col class="d-flex" cols="6">
                        <v-text-field label="tiempoAntesLectura" outlined dense hide-details
                            v-model="tiempoAntesLectura">
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-subheader>
                        Tema
                    </v-subheader>
                    <v-col class="d-flex" cols="12">
                        <v-switch v-model="$vuetify.theme.dark" :label="`Modo oscuro`"></v-switch>
                    </v-col>

                </v-row>
            </v-container>

        </v-navigation-drawer>
    </v-sheet>
</template>
<script>
import { watch, ref, onBeforeMount, onBeforeUpdate } from '@vue/composition-api'
import store from '@/store'
import config from '@/api/config'
import { acciones } from '@/modulos'
export default {
    props: {
        isDrawerOpen: {
            type: Boolean,
            default: null,
        },
    },
    setup() {


        const modeloImpresora = ref(1)
        const puerto = ref(5)
        const bAudios = ref(9600)
        const paridad = ref(2)
        const datos = ref(8)
        const stopBits = ref(1)
        const handshake = ref(0)
        const maxTiempoEspera = ref(5000)
        const tiempoAntesLectura = ref(100)
        /*
         const impresoraFiscal = ref({
             modeloImpresora: 1,
             puerto: 5,
             bAudios: 9600,
             paridad: 2,
             datos: 8,
             stopBits: 1,
             handshake: 0,
             maxTiempoEspera: 5000,
             tiempoAntesLectura: 100,
         })
         */
        watch(modeloImpresora, () => {

            store.commit('setImpresoraFiscal', {
                modeloImpresora: modeloImpresora.value,
                puerto: puerto.value,
                bAudios: bAudios.value,
                paridad: paridad.value,
                datos: datos.value,
                stopBits: stopBits.value,
                handshake: handshake.value,
                maxTiempoEspera: maxTiempoEspera.value,
                tiempoAntesLectura: tiempoAntesLectura.value,
            })
        })
        watch(puerto, () => {

            store.commit('setImpresoraFiscal', {
                modeloImpresora: modeloImpresora.value,
                puerto: puerto.value,
                bAudios: bAudios.value,
                paridad: paridad.value,
                datos: datos.value,
                stopBits: stopBits.value,
                handshake: handshake.value,
                maxTiempoEspera: maxTiempoEspera.value,
                tiempoAntesLectura: tiempoAntesLectura.value,
            })
        })
        watch(bAudios, () => {

            store.commit('setImpresoraFiscal', {
                modeloImpresora: modeloImpresora.value,
                puerto: puerto.value,
                bAudios: bAudios.value,
                paridad: paridad.value,
                datos: datos.value,
                stopBits: stopBits.value,
                handshake: handshake.value,
                maxTiempoEspera: maxTiempoEspera.value,
                tiempoAntesLectura: tiempoAntesLectura.value,
            })
        })
        watch(paridad, () => {

            store.commit('setImpresoraFiscal', {
                modeloImpresora: modeloImpresora.value,
                puerto: puerto.value,
                bAudios: bAudios.value,
                paridad: paridad.value,
                datos: datos.value,
                stopBits: stopBits.value,
                handshake: handshake.value,
                maxTiempoEspera: maxTiempoEspera.value,
                tiempoAntesLectura: tiempoAntesLectura.value,
            })
        })
        watch(datos, () => {

            store.commit('setImpresoraFiscal', {
                modeloImpresora: modeloImpresora.value,
                puerto: puerto.value,
                bAudios: bAudios.value,
                paridad: paridad.value,
                datos: datos.value,
                stopBits: stopBits.value,
                handshake: handshake.value,
                maxTiempoEspera: maxTiempoEspera.value,
                tiempoAntesLectura: tiempoAntesLectura.value,
            })
        })
        watch(stopBits, () => {

            store.commit('setImpresoraFiscal', {
                modeloImpresora: modeloImpresora.value,
                puerto: puerto.value,
                bAudios: bAudios.value,
                paridad: paridad.value,
                datos: datos.value,
                stopBits: stopBits.value,
                handshake: handshake.value,
                maxTiempoEspera: maxTiempoEspera.value,
                tiempoAntesLectura: tiempoAntesLectura.value,
            })
        })
        watch(handshake, () => {

            store.commit('setImpresoraFiscal', {
                modeloImpresora: modeloImpresora.value,
                puerto: puerto.value,
                bAudios: bAudios.value,
                paridad: paridad.value,
                datos: datos.value,
                stopBits: stopBits.value,
                handshake: handshake.value,
                maxTiempoEspera: maxTiempoEspera.value,
                tiempoAntesLectura: tiempoAntesLectura.value,
            })
        })
        watch(maxTiempoEspera, () => {

            store.commit('setImpresoraFiscal', {
                modeloImpresora: modeloImpresora.value,
                puerto: puerto.value,
                bAudios: bAudios.value,
                paridad: paridad.value,
                datos: datos.value,
                stopBits: stopBits.value,
                handshake: handshake.value,
                maxTiempoEspera: maxTiempoEspera.value,
                tiempoAntesLectura: tiempoAntesLectura.value,
            })
        })
        watch(tiempoAntesLectura, () => {

            store.commit('setImpresoraFiscal', {
                modeloImpresora: modeloImpresora.value,
                puerto: puerto.value,
                bAudios: bAudios.value,
                paridad: paridad.value,
                datos: datos.value,
                stopBits: stopBits.value,
                handshake: handshake.value,
                maxTiempoEspera: maxTiempoEspera.value,
                tiempoAntesLectura: tiempoAntesLectura.value,
            })
        })
        const validarAcciones = accion => {
            // const valor = store.state.user.accion_modulo.includes(accion)
            const valor = 59;

            return valor
        }

        onBeforeMount(() => {

            modeloImpresora.value = store.state.impresoraFiscal.modeloImpresora
            puerto.value = store.state.impresoraFiscal.puerto
            bAudios.value = store.state.impresoraFiscal.bAudios
            paridad.value = store.state.impresoraFiscal.paridad
            datos.value = store.state.impresoraFiscal.datos
            stopBits.value = store.state.impresoraFiscal.stopBits
            handshake.value = store.state.impresoraFiscal.handshake
            maxTiempoEspera.value = store.state.impresoraFiscal.maxTiempoEspera
            tiempoAntesLectura.value = store.state.impresoraFiscal.tiempoAntesLectura

           
        })


        const selectTikera = (item) => {
            let dato = {}
            impresoras.value.forEach(i => {
                if (i.id.toString() == item.toString()) {
                    dato = i
                }
            }
            )

            store.commit('seTikera', dato)
        }
        const impresoras = ref([
            {
                id: 1,
                nombre: "comanda 1"
            },
            {
                id: 2,
                nombre: "comanda 2"
            },
            {
                id: 3,
                nombre: "comanda 3"
            },
            {
                id: 4,
                nombre: "caja 1"
            },
            {
                id: 5,
                nombre: "caja 2"
            }
        ])
        const drawer = ref(null)
        const items = ref([
            { title: 'Home', icon: 'mdi-view-dashboard' },
            { title: 'About', icon: 'mdi-forum' },
        ])

        return {
            drawer,
            items,
            config,
            store,
            impresoras,
            selectTikera,
            modeloImpresora,
            puerto,
            bAudios,
            paridad,
            datos,
            stopBits,
            handshake,
            maxTiempoEspera,
            tiempoAntesLectura,
            validarAcciones,
            acciones
        }
    }
}
</script>