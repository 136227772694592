var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('vertical-nav-menu',{attrs:{"is-drawer-open":_vm.isDrawerOpen},on:{"update:isDrawerOpen":function($event){_vm.isDrawerOpen=$event},"update:is-drawer-open":function($event){_vm.isDrawerOpen=$event}}}),_c('menu-vertical-config',{attrs:{"is-drawer-open":_vm.isDrawerOpen2},on:{"update:isDrawerOpen":function($event){_vm.isDrawerOpen2=$event},"update:is-drawer-open":function($event){_vm.isDrawerOpen2=$event}}}),_c('Alert'),_c('v-app-bar',{attrs:{"app":"","flat":"","fixed":"","color":"dark"}},[_c('div',{staticClass:"boxed-container w-full"},[_c('div',{staticClass:"d-flex align-center mx-6"},[_c('v-app-bar-nav-icon',{staticClass:"d-block d-lg-none me-2",on:{"click":function($event){_vm.isDrawerOpen = !_vm.isDrawerOpen}}}),_c('select-img'),_c('v-spacer'),(_vm.funciones.validarAcciones(_vm.acciones.COORDENADAS_GENERAR_COORDENADA, -1))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ms-2",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.abrirHashClave()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiLockOpenAlertOutline)+" ")])],1)]}}],null,false,2747769964)},[_c('span',[_vm._v("Coordenadas")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[(_vm.workerEstatus)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCogPlayOutline)+" ")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCogStop)+" ")])],1)]}}])},[(_vm.workerEstatus)?_c('span',[_vm._v("Worker Corriendo")]):_c('span',[_vm._v("Worker Detenido o no esta instalado")])]),_c('theme-switcher',_vm._g(_vm._b({staticClass:"ms-2"},'theme-switcher',_vm.attrs,false),_vm.on)),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ms-2",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.isDrawerOpen2 = !_vm.isDrawerOpen2}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiMenuOpen)+" ")])],1)]}}])},[_c('span',[_vm._v("Abirir menu config")])]),_c('app-bar-user-menu',{on:{"AbrirAvatar":_vm.AbrirAvatar,"AbrirConfig":_vm.AbrirConfig}})],1)])]),_c('v-main',[_c('div',{staticClass:"app-content-container pa-6"},[_vm._t("default")],2)]),(this.$route.meta.IndFooter)?_c('v-footer',{attrs:{"dark":"","padless":""}},[_c('v-card',{staticClass:"flex",attrs:{"flat":"","tile":""}},[_c('v-card-text',{staticClass:"white--text text-center"},[_vm._v(" "+_vm._s(new Date().getFullYear())+" — "),_c('strong',[_vm._v("ArtySystem")])])],1)],1):_vm._e(),_c('avatar-select',{ref:"AvatarSelectRef"}),_c('ModalHashClave',{ref:"ModalHashClaveRef"}),_c('ConsultarMonedas')],1)}
var staticRenderFns = []

export { render, staticRenderFns }